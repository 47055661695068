@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    height: 100%;
    width: 100%;
    display: grid;
    position: absolute;
    grid-template-columns: minmax(0,1fr) minmax(500px, 1fr) minmax(0,1fr);
    grid-template-areas: ". main right";
}


/* For large tablets */
@media only screen and (max-width: 1200px) {
    .App {
        grid-template-columns: minmax(20px,80px) minmax(570px, 1fr) minmax(20px,80px);
        grid-template-rows: 1fr 1fr;
        grid-template-areas: ". main ." ". right .";
    }
}

/* For smaller tablets */
/*@media only screen and (max-width: 800px) {*/
/*    .App {*/
/*        grid-template-columns: minmax(20px,1fr) minmax(550px, 1fr) minmax(20px,1fr);*/
/*    }*/
/*}*/

/* For mobile phones */
@media only screen and (max-width: 600px) {
    .App {
        grid-template-columns: minmax(10px,20px) minmax(300px, 600px) minmax(10px,20px);
        grid-template-rows: 1fr 1fr;
        grid-template-areas: ". main ." ". right .";
    }
}


.MainDisplay_main__2mH2p  {
    grid-area: main;
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 30px;
    grid-template-rows: 100px 1fr 60px 60px 42px 0.5fr;
}

.MainDisplay_title__2U0sB {
    font-size: 40px;
    text-align: center;
    color: #143b26;
    font-weight: bolder;
    font-family: "Roboto";
}

.MainDisplay_dropdown__1T00P {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
}

.MainDisplay_doubleButton__3BfXF {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
}

.MainDisplay_audio__2Gca4 {
    width: 100%;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .MainDisplay_dropdown__1T00P {
        display: grid;
        grid-template-columns:1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 10px;
    }

    .MainDisplay_doubleButton__3BfXF {
        display: grid;
        grid-template-columns:1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 30px;
    }

    .MainDisplay_main__2mH2p  {
        grid-area: main;
        height: 100%;
        width: 100%;
        display: grid;
        grid-row-gap: 30px;
        grid-template-rows: 100px 300px 160px 60px 130px 0;
    }
}


.CustomDropdown_parent__1eUTb {
    width: 100%;
}
.CustomDropdown_item__1tYFX:hover {
    background-color: #d2d2d8 !important;
}

.TextField_textArea__1zGhq {
    height: 100%;
    width: 100%;
    background-color: #ededf4;
    border: solid 2px #7d7b7a;
    border-radius: 15px;
    position: relative;
}

.TextField_textArea__1zGhq:hover {
    border: solid 2px #3b3a3a;
}

.TextField_limitMessage__1Wclj {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #888888;
}

.CustomMenuButton_menuButton__2p7Du {
    display: grid;
    grid-template-columns: 1fr minmax(10px, 50px);
    border-radius: 5px;
}

.RightDisplay_right__2SllD {
    grid-area: right;
    height: 100%;
    width: 100%;
}

.RightDisplay_bottomRight__1tfwl{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
    width: 180px;
    height: 40px;
}

/* For large tablets */
@media only screen and (max-width: 1200px) {
    .RightDisplay_bottomRight__1tfwl{
        position: relative;
        right: 0;
        bottom: 0;
        padding: 0;
        width: 100%;
        height: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .RightDisplay_bottomRight__1tfwl{
        height: 50px;
        margin-bottom: 30px;
    }
}

.FeedbackBox_container__hm-U7 {
  position: absolute;
  right: 20px;
  bottom: 80px;
  width: 300px;
  height: 400px;
  padding: 20px;
  background-color: #246c46;
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: 25px 20px 3fr auto 1fr;
}

.FeedbackBox_top__PGwTa {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 50px;
}

.FeedbackBox_heading__nKvjo {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #ededf4;
  font-family: "Roboto";
  margin: 0;
}

.FeedbackBox_subtitle__3o9FD {
  color: #ededf4;
  font-family: "Roboto";
  margin: 0;
}

.FeedbackBox_backdrop__2C7T_ {
    z-index: 1;
    color: "#fff";
}

.FeedbackBox_captcha__k9cBc {
    justify-self: center;
}


.FeedbackBox_button__1OwWL{
    position: relative;
    top: -10px;
    right: -10px;
}

.FeedbackBox_feedbackBoxOpen__3eoeM {
    /*height: 100%;*/
    width: 100%;
}

/* For large tablets */
@media only screen and (max-width: 1200px) {
    .FeedbackBox_feedbackBoxOpen__3eoeM {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
    }

    .FeedbackBox_container__hm-U7 {
        width: 500px;
        margin-top: 1%;
        margin-bottom: 5%;
        height: 90%;
        /* 20px padding on both sides */
        right: calc(50% - 540px/2);
        top: 0;
        bottom: 0;

    }
}

@media only screen and (max-width: 600px) {
    .FeedbackBox_container__hm-U7 {
        width: 80%;
        right: calc(50% - (80% + 40px)/2 );
    }


}


.textArea {
    height: 100%;
    width: 100%;
    background-color: #ededf4;
    border: solid 2px #7d7b7a;
    border-radius: 15px;
    position: relative;
}

.textArea:hover {
    border: solid 2px #3b3a3a;
}

.limitMessage {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  user-select: none;
  color: #888888;
}

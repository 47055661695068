.App {
    height: 100%;
    width: 100%;
    display: grid;
    position: absolute;
    grid-template-columns: minmax(0,1fr) minmax(500px, 1fr) minmax(0,1fr);
    grid-template-areas: ". main right";
}


/* For large tablets */
@media only screen and (max-width: 1200px) {
    .App {
        grid-template-columns: minmax(20px,80px) minmax(570px, 1fr) minmax(20px,80px);
        grid-template-rows: 1fr 1fr;
        grid-template-areas: ". main ." ". right .";
    }
}

/* For smaller tablets */
/*@media only screen and (max-width: 800px) {*/
/*    .App {*/
/*        grid-template-columns: minmax(20px,1fr) minmax(550px, 1fr) minmax(20px,1fr);*/
/*    }*/
/*}*/

/* For mobile phones */
@media only screen and (max-width: 600px) {
    .App {
        grid-template-columns: minmax(10px,20px) minmax(300px, 600px) minmax(10px,20px);
        grid-template-rows: 1fr 1fr;
        grid-template-areas: ". main ." ". right .";
    }
}


.main  {
    grid-area: main;
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 30px;
    grid-template-rows: 100px 1fr 60px 60px 42px 0.5fr;
}

.title {
    font-size: 40px;
    text-align: center;
    color: #143b26;
    font-weight: bolder;
    font-family: "Roboto";
}

.dropdown {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
}

.doubleButton {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
}

.audio {
    width: 100%;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .dropdown {
        display: grid;
        grid-template-columns:1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 10px;
    }

    .doubleButton {
        display: grid;
        grid-template-columns:1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 30px;
    }

    .main  {
        grid-area: main;
        height: 100%;
        width: 100%;
        display: grid;
        grid-row-gap: 30px;
        grid-template-rows: 100px 300px 160px 60px 130px 0;
    }
}


.container {
  position: absolute;
  right: 20px;
  bottom: 80px;
  width: 300px;
  height: 400px;
  padding: 20px;
  background-color: #246c46;
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: 25px 20px 3fr auto 1fr;
}

.top {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 50px;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #ededf4;
  font-family: "Roboto";
  margin: 0;
}

.subtitle {
  color: #ededf4;
  font-family: "Roboto";
  margin: 0;
}

.backdrop {
    z-index: 1;
    color: "#fff";
}

.captcha {
    justify-self: center;
}


.button{
    position: relative;
    top: -10px;
    right: -10px;
}

.feedbackBoxOpen {
    /*height: 100%;*/
    width: 100%;
}

/* For large tablets */
@media only screen and (max-width: 1200px) {
    .feedbackBoxOpen {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
    }

    .container {
        width: 500px;
        margin-top: 1%;
        margin-bottom: 5%;
        height: 90%;
        /* 20px padding on both sides */
        right: calc(50% - 540px/2);
        top: 0;
        bottom: 0;

    }
}

@media only screen and (max-width: 600px) {
    .container {
        width: 80%;
        right: calc(50% - (80% + 40px)/2 );
    }


}

.right {
    grid-area: right;
    height: 100%;
    width: 100%;
}

.bottomRight{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
    width: 180px;
    height: 40px;
}

/* For large tablets */
@media only screen and (max-width: 1200px) {
    .bottomRight{
        position: relative;
        right: 0;
        bottom: 0;
        padding: 0;
        width: 100%;
        height: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .bottomRight{
        height: 50px;
        margin-bottom: 30px;
    }
}
